<template>
  <div class="py-16">
    <!-- <p>{{ document }}</p> -->
    <v-img
      :src="require('@/assets/logo.svg')"
      class="mt-0 mb-5"
      contain
      height="38"
    >
    </v-img>
    <p class="blue-grey--text text-center mb-12">
      Repository of Vocabularies and Schemas. β1.0
    </p>

    <v-container class="pa-0">
      <v-tabs v-model="tab" centered class="pa-0" color="black">
        <v-tab
          v-for="(m, i) in menus"
          :key="i"
          :href="'#' + m"
          @click="handleChangeTab(m)"
        >
          {{ m }}
        </v-tab>
        <v-tabs-slider color="#8F5F98"></v-tabs-slider>
      </v-tabs>
    </v-container>
    <v-divider class="mb-9"></v-divider>

    <v-container>
      <v-list max-width="640" class="mx-auto">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ getWords("document_m1") }}
            </v-list-item-title>
            <p class="caption">
              {{ getWords("document_m3") }}: 2023/03/20
            </p>
          </v-list-item-content>
          <v-list-item-action>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="downloadBtnPressed(0)">
                  <v-list-item-icon>
                    <v-icon>mdi-download</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>DOWNLOAD</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ getWords("document_m2") }}
            </v-list-item-title>
            <p class="caption">
              {{ getWords("document_m3") }}: 2023/03/20
            </p>
          </v-list-item-content>
          <v-list-item-action>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="downloadBtnPressed(1)">
                  <v-list-item-icon>
                    <v-icon>mdi-download</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>DOWNLOAD</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-container>
  </div>
</template>

<script>
// import { db } from "@/plugins/firebase";
// import moment from "moment";
// import MarkdownViewer from "../components/MarkdownViewer.vue";
import {
  downloadDocuments
} from "../plugins/firebase";
import words from "../assets/localewords.json";

export default {
  name: "ResourcesDetail",

  // components: {
  //   MarkdownViewer,
  // },

  data() {
    return {
      distJson: { type: "LOADING", body: [] },
      menus: ["DOCUMENT"],
      selectTab: "DOCUMENT",
      tab: null,
    };
  },

  async created() {},

  computed: {
    getLanguage() {
      return this.$store.state.language;
    },
  },

  methods: {
    async handleChangeTab(target) {
      this.selectTab = target;
    },

    getWords(key) {
      let lang = this.getLanguage;
      if (lang !== "en" && lang !== "ja") {
        lang = "en";
      }
      const word = words[key] ? words[key][lang] : "";
      return word;
    },
    async downloadBtnPressed(index) {
      const docs = [
        { key: "apidoc", file: "VOCs API仕様.pdf" },
        { key: "manual", file: "VOCs_操作マニュアル.pptx" },
      ]

      const docObj = index === 0 ? docs[0] : docs[1];
      try {
        const blob = await downloadDocuments(docObj.key)
        const link = document.createElement("a");
        if (window.navigator.msSaveOrOpenBlob) {
          /* msSaveOrOpenBlobの場合はファイルを保存せずに開ける */
          window.navigator.msSaveOrOpenBlob(blob, docObj.file);
        } else if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(blob, docObj.file);
        } else {
          const url = window.URL.createObjectURL(blob);
          link.download = docObj.file;
          link.href = url;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
};
</script>

<style scoped></style>
